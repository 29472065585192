import React from 'react';
import AlignCenter from '../../pages/AlignCenter';
import { goSingleStory } from '../../utils';
import StoryDisplay from '../StoryDisplay';

const Stories = ({ item, classes, hoverStyle, storyImageLoaded, index, history }) => {
	return (
		<div data-aos="fade" data-aos-delay={40 * index} className={hoverStyle.block1}>
			<AlignCenter>
				{item && item.image && (
					<StoryDisplay
						story={item}
						onClick={() => goSingleStory(item.slug, history)}
						onLoad={storyImageLoaded}
					/>
				)}
			</AlignCenter>
		</div>
	);
};

export default Stories;
