import React from 'react';
import Masonry from 'react-masonry-css';
import { goSingleStory } from '../../utils'
import StoryDisplay from '../StoryDisplay';

export default function RelatedStories({ setLoader, classes, story, history }) {
	return (
		<Masonry
			breakpointCols={{
				default: 3,
				1900: 3,
				1460: 2,
				980: 1
			}}
			className={classes.masonryGrid}
			columnClassName={classes.masonryGridColumn}
		>
			{story?.related_stories.map((item, i) => (
				<div data-aos="fade" data-aos-delay={40 * i} key={i} className={classes.container}>
					<StoryDisplay
						story={item}
						onClick={() => goSingleStory(item.slug, history)}
						onLoad={null}
					/>
				</div>
			))}
		</Masonry>
	);
}
