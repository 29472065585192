import React, { useEffect, useRef, useState } from 'react';
import './Waveform.css';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import Aos from 'aos';
import { storiesStyles } from './style';
import { getStoriesVideo } from '../services/storyService';
import 'aos/dist/aos.css';
import AlignCenter from './AlignCenter';
import Preloader from '../preloader/preloader';
import { goFrontPage, goSingleStory } from '../utils';
import hoverStyle from './hover.module.css';
import StoryDisplay from '../components/StoryDisplay';

const width = window.innerWidth;
const Stories = ({ history }) => {
	const classes = storiesStyles();
	const [stories, setStories] = useState(null);
	const [loader, setLoader] = useState(true);
	const [page, setPage] = useState(0);
	const _storyImageLoad = useRef(0);

	useEffect(() => {
		if (!stories) {
			getStoriesVideo(res => {
				setStories(res.results);
			});
		}
	}, [stories]);

	useEffect(() => {
		Aos.init({
			duration: 500,
			startEvent: 'storyImagesLoaded',
			once: true
		});
		Aos.refresh();
	});

	useEffect(() => {
		const onScroll = e => {
			if (document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 100) {
				setPage(page + 1);
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	});

	const openLink = () => {
		const win = window.open('https://www.ineverread.com/', '_blank');
		win.focus();
	};

	const storyImageLoaded = () => {
		_storyImageLoad.current += 1;
		if (_storyImageLoad.current === 5) {
			document.dispatchEvent(new Event('storyImagesLoaded', { bubbles: true }));
			setLoader(false);
		}
	};

	return (
		<>
			<div style={{ opacity: !stories || loader ? '1' : '0' }} className={classes.loaderContainer}>
				<Preloader />
			</div>
			<div>
				{stories && (
					<Masonry
						breakpointCols={{
							default: 3,
							1900: 3,
							1460: 2,
							980: 1
						}}
						className={classes.masonryGrid}
						columnClassName={classes.masonryGridColumn}
					>
						{stories.map((item, i) => (
							<div data-aos="fade" data-aos-delay={40 * i} key={i} className={hoverStyle.block1}>
								<AlignCenter>
									{item && item.vimeo_id && (
										<StoryDisplay
											story={item}
											onClick={() => goSingleStory(item.slug, history)}
											onLoad={storyImageLoaded}
										/>
									)}
								</AlignCenter>
							</div>
						))}
					</Masonry>
				)}
				<Grid className={classes.centerText}>
					<Grid className={classes.rightText} style={{ right: width > 992 ? 20 : 0 }}>
						<Typography className={classes.text} onClick={() => goFrontPage(history)}>
							OOO
						</Typography>
					</Grid>
					<Grid
						onClick={() => openLink()}
						className={classes.leftText}
						style={{ left: width > 992 ? 20 : 0 }}
					>
						<Typography className={classes.text}>I Never Read,</Typography>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default withRouter(Stories);
