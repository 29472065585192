import React from 'react';
import { CardMedia } from '@material-ui/core';
import { singleStoryStyles } from '../../pages/style';

const StoryDisplay = ({ story, onClick, onLoad }) => {
    const classes = singleStoryStyles();

    return (
        <CardMedia
            className={classes.storyImage}
            component="img"
            alt="story-image"
            width={400}
            image={story.image}
            onClick={() => onClick && onClick()}
            onLoad={() => onLoad && onLoad()}
        />
    );
};

export default StoryDisplay;
